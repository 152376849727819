import { useState, useEffect } from "react";
import { PlusIcon } from "@heroicons/react/24/solid";
import { useLists } from "../components/ListComponent";
import Spinner from "./Spinner";
import Link from "next/link";
import * as R from "ramda";

export default function ListSelectBox({
  onSave,
}: {
  onSave: (id: string) => void;
}) {
  const { lists, loading } = useLists();
  const [targetList, setTargetList] = useState<string | undefined>();

  useEffect(() => {
    if (R.isNil(targetList) && lists.length > 0) {
      setTargetList(lists[0].id);
    }
  }, [lists]);

  if (loading) {
    return (
      <div className="flex flex-col items-center">
        <Spinner className="!mx-auto mb-5 h-7 w-7" />
      </div>
    );
  }

  if (lists.length === 0) {
    return (
      <div className="flex flex-col items-center">
        <p className="text-gray-500 text-sm mb-2">
          {"You don't have any lists yet."}
        </p>
        <Link href="/lists">
          <button className="flex items-center text-gray-500 text-sm hover:text-gray-700">
            <PlusIcon className="h-4 w-4 mr-1" />
            Create a new list
          </button>
        </Link>
      </div>
    );
  }

  return (
    <div>
      <div>
        <label
          htmlFor="List"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Select List
        </label>
        <select
          id="list"
          name="Lists"
          className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
          value={targetList}
          onChange={(e) => setTargetList(e.target.value)}
          defaultValue={targetList}
        >
          {lists.map((list) => {
            return (
              <option key={list.label} value={list.id}>
                {list.label}
              </option>
            );
          })}
        </select>
      </div>
      <div className="text-center">
        <button
          onClick={(e) => {
            if (targetList) {
              onSave(targetList);
            }
          }}
          className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-2"
        >
          <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          Add To List
        </button>
      </div>
    </div>
  );
}
