import { ReactElement } from "react";
import * as R from "ramda";
import { useSession } from "../utils/graphql";
import NullValue from "./NullValue";
import Link from "next/link";

export default function UserMaskValue({
  val,
  className,
  defaultText = "Upgrade To View",
}: {
  val?: string;
  className?: string;
  defaultText?: string;
}): ReactElement {
  const { state } = useSession();
  if (
    state.loading ||
    R.isNil(state.data?.user?.hasPlan) ||
    !state.data?.user?.hasPlan
  ) {
    return <Link href="/select-plan">{defaultText}</Link>;
  }
  return <NullValue val={val} className={className} />;
}
