import Empty from "./Empty";
import { UserPlusIcon } from "@heroicons/react/24/solid";

export default function EmptyList() {
  return (
    <div className="py-10">
      <Empty
        primary="You have no new prospects to process"
        secondary="Install the browser plugin to collect contacts while browsing."
        cta="Install Plugin"
        href="https://chrome.google.com/webstore/detail/mailmentor-prospecting-co/lmaejlipbkiphadkcbhmmpaoephkgmpj"
        videoUrl="https://youtu.be/eutnBTh5JxE?si=b0HEw-1HTqywjcyP"
        icon={<UserPlusIcon className="mx-auto h-12 w-12 text-gray-400" />}
      />
    </div>
  );
}
