import React, { useState, ReactNode } from "react";

interface TooltipProps {
  children: ReactNode;
  tip: string;
}

const Tooltip: React.FC<TooltipProps> = ({ children, tip }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [position, setPosition] = useState<"top" | "bottom">("bottom");

  const handleMouseEnter = (event: React.MouseEvent) => {
    setShowTooltip(true);
    setPosition(event.clientY > window.innerHeight / 2 ? "top" : "bottom");
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className="relative inline-block"
    >
      <div className="block">{children}</div>
      {showTooltip && (
        <div
          className={`
            absolute
            left-1/2
            transform -translate-x-1/2
            py-1 px-2
            text-white
            bg-gray-800
            rounded
            text-sm
            z-50
            w-[200px]
            text-center
            ${position === "top" ? "bottom-full" : "top-full"}
          `}
        >
          {tip}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
