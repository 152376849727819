import { ReactElement } from "react";
import * as R from "ramda";

export default function NullValue({
  val,
  className,
  defaultText = "Not Found",
}: {
  val?: string;
  className?: string;
  defaultText?: string;
}): ReactElement {
  if (R.isNil(val) || R.isEmpty(val)) {
    return <span className="text-gray-300">{defaultText}</span>;
  }
  return <span className={className}>{val}</span>;
}
