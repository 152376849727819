import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import {
  InstantSearch,
  useRefinementList,
  useSearchBox,
  useInstantSearch,
} from "react-instantsearch-hooks-web";
import { history } from "instantsearch.js/es/lib/routers";
import algoliasearch from "algoliasearch/lite";
import * as R from "ramda";
import { useSession } from "../utils/graphql";
import Spinner from "./Spinner";

type State = {
  filters: Set<string>;
  search?: string;
  isSearching: boolean;
  results: any[];
  isFiltering: boolean;
};

// Create the context
const SearchContext =
  createContext<
    | {
        state: State;
        dispatch: React.Dispatch<Action>;
      }
    | undefined
  >(undefined);

type Action =
  | { type: "SET_SEARCH"; payload: string }
  | { type: "SET_FILTER"; payload: string }
  | { type: "REMOVE_FILTER"; payload: string }
  | { type: "SET_RESULTS"; payload: any[] };
// Add other action types as needed

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "SET_SEARCH":
      const isSearching =
        !R.isNil(action.payload) && !R.isEmpty(action.payload);
      return { ...state, search: action.payload, isSearching };
    case "SET_FILTER":
      const newFilters = new Set(state.filters);
      newFilters.add(action.payload);
      return { ...state, filters: newFilters, isFiltering: true };
    case "REMOVE_FILTER":
      const updatedFilters = new Set(state.filters);
      updatedFilters.delete(action.payload);
      return {
        ...state,
        filters: updatedFilters,
        isFiltering: updatedFilters.size > 0,
      };
    case "SET_RESULTS":
      return { ...state, results: action.payload };
    default:
      return state;
  }
}

export function useRefinements({
  attribute,
  limit = 5,
  sortBy,
}: {
  attribute: string;
  limit?: number;
  sortBy?: string[];
}) {
  const context = useContext(SearchContext);

  if (!context) {
    throw new Error("useRefinement must be used within a SearchProvider");
  }

  const { state, dispatch } = context;

  const {
    items: possibleRefinements,
    refine: addRefinement,
    canToggleShowMore,
    hasExhaustiveItems,
    toggleShowMore: showMore,
    isShowingMore,
  } = useRefinementList({
    attribute,
    limit,
    sortBy: sortBy as any[],
  });

  const filter = (value: string) => {
    dispatch({ type: "SET_FILTER", payload: [attribute, value].join(":") });
    addRefinement(value);
  };

  const removeFilter = (value: string) => {
    dispatch({ type: "REMOVE_FILTER", payload: [attribute, value].join(":") });
    addRefinement(value);
  };

  return {
    possibleRefinements,
    filter,
    removeFilter,
    showMore,
    canShowLess: isShowingMore && hasExhaustiveItems,
    canShowMore: !hasExhaustiveItems,
    //canShowMore: !canToggleShowMore,
  };
}

// Create a hook for easy context usage
export function useSearch() {
  const context = useContext(SearchContext);

  if (!context) {
    throw new Error("useSearch must be used within a SearchProvider");
  }

  const { state, dispatch } = context;

  const { refine: refineSearch, clear: clearSearch } = useSearchBox();
  const { results } = useInstantSearch();

  useEffect(() => {
    if (
      !state.isSearching ||
      R.isNil(state.search) ||
      R.isEmpty(state.search)
    ) {
      clearSearch();
      return;
    }
    refineSearch(state.search);
  }, [state.search, state.isSearching, clearSearch, refineSearch]);

  useEffect(() => {
    if (R.isNil(results)) {
      dispatch({ type: "SET_RESULTS", payload: [] });
      return;
    }

    dispatch({ type: "SET_RESULTS", payload: results.hits });
  }, [results, dispatch]);

  const search = (value: string) => {
    if (R.isEmpty(value) || R.isNil(value)) {
      dispatch({ type: "SET_SEARCH", payload: "" });
      return;
    }
    dispatch({ type: "SET_SEARCH", payload: value });
  };

  return {
    state,
    search,
  };
}

// Create the provider component
export function Provider({
  children,
  indexName,
}: {
  children: React.ReactNode;
  indexName: string;
}) {
  const initialState: State = {
    filters: new Set(),
    isSearching: false,
    results: [],
    isFiltering: false,
  };
  const { state: session } = useSession();
  const [state, dispatch] = useReducer(reducer, initialState);

  const client = useMemo(() => {
    if (session.data.user?.searchKey) {
      return algoliasearch(
        process.env.NEXT_PUBLIC_ALGOLIA_APP_ID || "",
        session.data.user.searchKey
      );
    }
    return undefined;
  }, [session.data.user?.searchKey]);

  if (!client) {
    return (
      <div className="flex flex-col items-center">
        <Spinner className="!mx-auto mb-5 h-7 w-7" />
        <div className="text-gray-900 font-medium">Loading</div>
      </div>
    );
  }

  return (
    <InstantSearch searchClient={client} indexName={indexName}>
      <SearchContext.Provider value={{ state, dispatch }}>
        {children}
      </SearchContext.Provider>
    </InstantSearch>
  );
}
