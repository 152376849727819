import { ReactNode } from "react";
import { useRefinements } from "./FilterAndSearch";
import * as Analytics from "../utils/analytics";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import * as R from "ramda";

function truncateText(myInput: string, length: number) {
  const match = myInput.match(/(DIRECT_SITE:|url:)(https:\/\/\S+)/);
  myInput = match ? match[2] : myInput;
  if (myInput.length <= length) {
    return myInput;
  }
  return myInput.substring(0, length) + "...";
}

const SideRefinements = function ({
  label,
  attribute,
  limit,
  sortBy,
}: {
  label: string;
  attribute: string;
  limit?: number;
  sortBy?: string[];
}) {
  const { possibleRefinements, filter, removeFilter, canShowMore, showMore } =
    useRefinements({
      attribute,
      limit,
      sortBy,
    });

  if (R.isNil(possibleRefinements) || R.isEmpty(possibleRefinements)) {
    return null;
  }

  return (
    <div className="pt-8">
      <form className="space-y-10 divide-y divide-gray-200">
        <fieldset
          style={{
            width: "100%",
            padding: "0",
            margin: "0",
            border: "none",
            minWidth: "0",
          }}
        >
          <legend className="block text-sm font-medium text-gray-900">
            {label}
          </legend>
          <div className="space-y-3 pt-2">
            {possibleRefinements.map((option, optionIdx) => (
              <div key={option.label} className="flex items-center w-full">
                <input
                  id={`${option.label}-${optionIdx}`}
                  name={`${option.label}[]`}
                  defaultValue={option.value}
                  checked={option.isRefined}
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  type="checkbox"
                  onChange={(e) => {
                    if (e.target.checked) {
                      Analytics.track("NerFilterAdd", {
                        attribute,
                        value: option.value,
                      });
                      filter(option.value);
                      return;
                    }
                    Analytics.track("NerFilterRemove", {
                      attribute,
                      value: option.value,
                    });
                    removeFilter(option.value);
                  }}
                />
                <label
                  htmlFor={`${option.label}-${optionIdx}`}
                  className="ml-3 text-sm text-gray-600 hover:text-indigo-500 text-ellipsis overflow-hidden whitespace-normal hover:overflow-visible hover:whitespace-normal z-20"
                >
                  <span className="pr-1 bg-white">
                    {truncateText(option.label, 40)}
                  </span>
                </label>
              </div>
            ))}
            {canShowMore && (
              <div
                className="flex text-sm hover:text-indigo-500 text-gray-400 hover:cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  showMore();
                }}
              >
                <span>More</span>
                <span>
                  <ChevronDownIcon
                    className="h-5 w-5 transform group-hover:text-indigo-500"
                    aria-hidden="true"
                  />
                </span>
              </div>
            )}
          </div>
        </fieldset>
      </form>
    </div>
  );
};

export default function SideFilter({
  children,
}: {
  children?: ReactNode | ReactNode[];
}) {
  return (
    <div className="lg:grid lg:grid-cols-6 gap-x-8 pt-4">
      <div className="pl-2 lg:grid-span-1">
        <div>
          <h3>Filters</h3>
        </div>
        <SideRefinements
          label={"Job Title"}
          attribute={"contactTitle"}
          limit={10}
          sortBy={["name:asc", "count:desc"]}
        />
        <SideRefinements
          label={"Company"}
          attribute={"company.label"}
          sortBy={["name:asc", "count:desc"]}
        />
      </div>

      <div className="lg:col-span-5">{children}</div>
    </div>
  );
}
