import { useEffect, useState, Dispatch, SetStateAction } from "react";
import * as R from "ramda";

export const useLsDefault = function <T>(
  lsKey: string,
  def: T
): [T, Dispatch<SetStateAction<T>>] {
  const [val, setVal] = useState<T>(def);

  useEffect(() => {
    let lsVal = localStorage.getItem(lsKey);
    if (lsVal) {
      try {
        const parsedVal = JSON.parse(lsVal) as T;
        setVal(parsedVal);
      } catch {
        // Nopt JSon
        setVal(lsVal as unknown as T);
      }
      return;
    }
  }, []);

  useEffect(() => {
    if (val && (!R.equals(val, def) || val != def)) {
      localStorage.setItem(lsKey, JSON.stringify(val));
    }
  }, [val]);

  return [val, setVal];
};
