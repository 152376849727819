import { Queries } from "../utils/graphql";
import { Fragment } from "react";
import { ReactSocialMediaIcons } from "react-social-media-icons";
import * as R from "ramda";

export default function SocialProfiles({
  profiles,
}: {
  profiles: Queries.ISocialProfile[];
}) {
  profiles.sort((a, b) => {
    if (a.service < b.service) {
      return -1;
    }
    if (a.service > b.service) {
      return 1;
    }
    return 0;
  });

  if (R.isEmpty(profiles)) {
    return null;
  }

  return (
    <div className="flex justify-left w-full pt-1">
      {profiles.map((prof, idx) => {
        if (prof.service === "PERSONAL_SITE") {
          return;
        }
        return (
          <Fragment key={idx}>
            <div className="pr-1">
              <ReactSocialMediaIcons
                key={"profile-icon" + idx}
                borderWidth={0}
                icon={prof.service.toLowerCase()}
                size="18"
                url={prof.url}
              />
            </div>
          </Fragment>
        );
      })}
    </div>
  );
}
