import { Fragment } from "react";
import type { NextPage } from "next";
import Link from "next/link";
import { useEffect, useState, useMemo, useCallback, ReactElement } from "react";
import { Queries, useSession } from "../utils/graphql";
import { useQuery, useMutation } from "@apollo/client";
import { ProtectedLayout as Layout } from "./Layout";
import Container from "./Container";
import "react-datasheet-grid/dist/style.css";
import * as Analytics from "../utils/analytics";
import * as R from "ramda";
import {
  HandThumbDownIcon,
  HandThumbUpIcon,
  MagnifyingGlassIcon,
  CheckBadgeIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import {
  QueueListIcon,
  FolderArrowDownIcon,
  ArchiveBoxIcon,
  ArrowPathIcon,
} from "@heroicons/react/24/solid";
import { classNames } from "../utils";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingSpinner from "./Spinner";
import { ReactSocialMediaIcons } from "react-social-media-icons";
import pThrottle from "p-throttle";
import Modal, { useModal } from "./Modal";
import ListSelectBox from "./ListSelectBox";
import Tooltip from "./Tooltip";
import * as FilterAndSearch from "./FilterAndSearch";
import SideFilter from "./SideFilter";
import { BloomFilter } from "bloomfilter";
import dayjs from "../utils/dayjs";
import NullValue from "./NullValue";
import UserMaskedValue from "./user-mask-value";
import SocialProfiles from "./SocialProfiles";
import EmptyList from "./EmptyProspects";

interface IDownloadableEntitiy {
  firstName: string;
  lastName: string;
  company: string;
  companyWebsite: string;
  title: string;
  source: string;
}

export const useCSVDownloader = () => {
  const convertToCSV = useCallback((array: IDownloadableEntitiy[]) => {
    const header = Object.keys(array[0]).join(",");
    const rows = array
      .map((item) =>
        Object.values(item)
          .map((value) =>
            typeof value === "string"
              ? `"${value.replace(/"/g, '""')}"`
              : value,
          )
          .join(","),
      )
      .join("\n");

    return `${header}\n${rows}`;
  }, []);

  const downloadCSV = useCallback(
    (array: Queries.IEntity[]) => {
      if (R.isEmpty(array.length)) {
        return;
      }
      Analytics.track("CSVDownload", { size: array.length });

      const downloadableEntities = array.map((ele) => {
        return {
          firstName: ele.firstName || "Not Found",
          lastName: ele.lastName || "Not Found",
          company: ele.company?.label || "Not Found",
          email: ele.emailAddress || "Not Found",
          companyWebsite: ele.company?.domain || "Not Found",
          title: ele.contactTitle || "Not Found",
          source: ele.source || "Not Found",
        };
      });
      const csvContent = convertToCSV(downloadableEntities);
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      const ts = dayjs();
      link.setAttribute(
        "download",
        `MailMentorLeads-${ts.format("MM-DD-h-mm-ss")}.csv`,
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    },
    [convertToCSV],
  );

  return { convertToCSV, downloadCSV };
};

const SourceUrl = ({ url }: { url: string }): ReactElement => {
  url = parseSourcePath(url);

  if (R.isNil(url)) {
    return <NullValue val={url} />;
  }

  return (
    <Link href={"https://" + url} passHref>
      <a target="_blank" rel="noopener noreferrer">
        {url}
      </a>
    </Link>
  );
};

const parseSourcePath = (val: string): string => {
  if (R.isNil(val)) {
    return val;
  }

  const pattern = "DIRECT_SITE:https://";
  let cleanedVal = val;
  if (val.includes(pattern)) {
    cleanedVal = val.replace(pattern, "");
  }

  const splits = cleanedVal.split("/");
  if (splits.length === 0) {
    return cleanedVal;
  }

  return splits[0].replace(/-/g, ".");
};

const throttle = pThrottle({
  limit: 4,
  interval: 500,
});

const EntitiesTable = ({
  entities,
  fetchMore,
  hasMore,
  onRefresh,
}: {
  entities: Queries.IEntity[];
  fetchMore: () => void;
  hasMore: boolean;
  onRefresh: () => void;
}) => {
  const {
    state: { search: searchTerm, results, isSearching, isFiltering },
    search,
  } = FilterAndSearch.useSearch();
  const { state } = useSession();

  const [_, setOpen] = useModal();
  const [actOnEntityMut] = useMutation(Queries.ACT_ON_ENTITIES_MUT);
  const [blackList, setBlacklist] = useState<string[]>([]);
  const [loadingList, setLoadingList] = useState<string[]>([]);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [thumbsUpIds, setThumbsUpIds] = useState<string[]>([]);
  const [alternateIdx, setAlternateIdx] = useState<number | undefined>();
  const { downloadCSV } = useCSVDownloader();

  const actOnEntity = throttle(async (id: string, action, vars?: Object) => {
    await actOnEntityMut({
      variables: {
        id,
        action,
        ...vars,
      },
    });
    setBlacklist((prevIds) => [...prevIds, id]);
    setLoadingList((prevIds) => {
      return prevIds.filter((myId) => myId != id);
    });
    setSelectedIds((prevIds) => {
      return prevIds.filter((myId) => myId != id);
    });
  });

  const enQueueAction = (id: string, action: string, vars?: Object) => {
    setLoadingList((prevIds) => [...prevIds, id]);
    Analytics.track("ProspectAction", { action, id });
    actOnEntity(id, action, vars);
  };

  // filter entitities according to blacklist
  const filteredEntities = useMemo(() => {
    let myEntities = entities;
    if (isSearching || isFiltering) {
      myEntities = results;
    }

    const bloom = new BloomFilter(
      32 * 256, // number of bits to allocate.
      16, // number of hash functions.
    );

    return myEntities.filter((ent) => {
      if (blackList.includes(ent.id)) {
        return false;
      }

      const requiredProps = [ent.firstName?.trim(), ent.lastName?.trim()];
      if (requiredProps.includes(undefined) || requiredProps.includes("")) {
        return false;
      }

      // Remove duplicates
      const nameComp = [
        ent.firstName?.trim(),
        ent.lastName?.trim(),
        ent.company?.label?.trim(),
      ].join(":");

      if (bloom.test(nameComp)) {
        // item not in filter
        return false;
      }
      bloom.add(nameComp);

      return true;
    });
  }, [blackList, entities, isSearching, isFiltering, results]);

  const onExport = useCallback(() => {
    const downloadableEntities = entities.filter((ent) => {
      return selectedIds.includes(ent.id);
    });
    downloadCSV(downloadableEntities);
  }, [selectedIds, entities, downloadCSV]);

  const actOnSelected = useCallback(
    async (action: string, vars?: Object) => {
      setLoading(true);
      try {
        await Promise.all(
          selectedIds.map((id) => {
            if (action == "THUMBS_UP") {
              Analytics.track("ProspectQualityThumbsUp", {
                id: id,
              });

              setThumbsUpIds((ids) => [...ids, id]);
              // Do nothing
              return;
            }

            if (action == "RAISE_EXCEPTION") {
              Analytics.track("ProspectQualityThumbsDown", {
                id: id,
              });
            }

            enQueueAction(id, action, vars);
          }),
        );
      } catch (err) {
        console.error(err);
      }
      setSelectedIds([]);
      setLoading(false);
    },
    [selectedIds],
  );

  const refresh = () => {
    setSelectedIds([]);
    if (onRefresh) {
      onRefresh();
    }
  };

  return (
    <div>
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <div className="mb-2">
            <div className="flex items-center">
              <div className="relative mt-1 flex-grow">
                <input
                  type="text"
                  name="search"
                  id="search"
                  className="block w-full rounded-md border-gray-300 pr-12 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={(e) => {
                    search(e.target.value);
                  }}
                />
                <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
                  <MagnifyingGlassIcon className="inline-flex items-center px-2 text-gray-400" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4 sm:ml-2 sm:mt-0 sm:flex sm:flex-row sm:space-x-2">
          {!isSearching && !isFiltering && (
            <button
              disabled={loading}
              type="button"
              className="block rounded-md bg-gray-400 px-3 py-1.5 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
              onClick={() => refresh()}
            >
              <ArrowPathIcon className="border-1 hover:text-white-600 h-5 w-5 text-white hover:cursor-pointer" />
            </button>
          )}
          {selectedIds.length > 0 && (
            <>
              <Tooltip tip="More leads like this">
                <button
                  disabled={loading}
                  type="button"
                  className="block rounded-md bg-gray-400 px-3 py-1.5 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                  onClick={() => {
                    actOnSelected("THUMBS_UP");
                  }}
                >
                  <HandThumbUpIcon className="border-1 hover:text-white-600 h-5 w-5 text-white hover:cursor-pointer" />
                </button>
              </Tooltip>
              <Tooltip tip="Report bad lead or data">
                <button
                  disabled={loading}
                  type="button"
                  className="rounded-md bg-gray-400 px-3 py-1.5 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                  onClick={() => {
                    actOnSelected("RAISE_EXCEPTION");
                  }}
                >
                  <HandThumbDownIcon className="border-1 hover:text-white-600 h-5 w-5 text-white hover:cursor-pointer" />
                </button>
              </Tooltip>
              <Tooltip tip="Add to list">
                <button
                  disabled={loading}
                  type="button"
                  className="rounded-md bg-blue-600 px-3 py-1.5 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  <QueueListIcon className="border-1 hover:text-white-600 h-5 w-5 text-white hover:cursor-pointer" />
                </button>
              </Tooltip>
              <Tooltip tip="Export selected to CSV">
                <button
                  disabled={loading}
                  type="button"
                  className="rounded-md bg-teal-600 px-3 py-1.5 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                  onClick={() => {
                    onExport();
                  }}
                >
                  <FolderArrowDownIcon className="border-1 hover:text-white-600 h-5 w-5 text-white hover:cursor-pointer" />
                </button>
              </Tooltip>
              <Tooltip tip="Remove from view">
                <button
                  disabled={loading}
                  type="button"
                  className="rounded-md bg-indigo-600 px-3 py-1.5 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  onClick={() => {
                    actOnSelected("ARCHIVE");
                  }}
                >
                  <ArchiveBoxIcon className="border-1 hover:text-white-600 h-5 w-5 text-white hover:cursor-pointer" />
                </button>
              </Tooltip>
            </>
          )}
        </div>
      </div>
      <div className="flow-root">
        <div className="-mx-4 sm:-mx-6 lg:-mx-8">
          <div className="inline-block w-full overflow-auto py-2 align-middle sm:px-6 lg:px-8">
            <InfiniteScroll
              style={{ overflow: undefined }}
              dataLength={filteredEntities.length}
              next={fetchMore}
              hasMore={
                hasMore && (R.isNil(searchTerm) || R.isEmpty(searchTerm))
              }
              loader={
                <div className="mt-4">
                  <LoadingSpinner />
                </div>
              }
            >
              <table className="min-w-full table-fixed divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-4 pl-3 pr-3 text-left text-sm font-semibold text-gray-900"
                    >
                      <input
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        checked={filteredEntities
                          .map((e) => e.id)
                          .every((id) => selectedIds.includes(id))}
                        onChange={(event) =>
                          event.target.checked
                            ? setSelectedIds(filteredEntities.map((e) => e.id))
                            : setSelectedIds([])
                        }
                      />
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      First Name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Last Name
                    </th>
                    <th
                      scope="col"
                      className="w-1 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Company
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Title
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <Tooltip tip="Alternates are estimated based on contact information">
                        <div className="flex items-center">
                          <div className="flex-none">Email</div>
                          <div className="flex-none">
                            <InformationCircleIcon className="h-5 w-5" />
                          </div>
                        </div>
                      </Tooltip>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {filteredEntities.map((entity, idx) => (
                    <Fragment key={"entry-" + idx}>
                      <tr
                        key={entity.id}
                        className={classNames(
                          "hover:bg-gray-50",
                          selectedIds.includes(entity.id)
                            ? "bg-gray-50"
                            : undefined,
                        )}
                      >
                        <td className="py-4 pl-3 pr-3 text-sm font-medium">
                          {loadingList.includes(entity.id) && (
                            <LoadingSpinner />
                          )}
                          {!loadingList.includes(entity.id) && (
                            <input
                              checked={selectedIds.includes(entity.id)}
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                              onChange={() =>
                                setSelectedIds(
                                  selectedIds.includes(entity.id)
                                    ? selectedIds.filter(
                                        (item) => item !== entity.id,
                                      )
                                    : [...selectedIds, entity.id],
                                )
                              }
                            />
                          )}
                        </td>
                        <td
                          className={classNames(
                            "py-4 pr-3 text-sm font-medium",
                            selectedIds.includes(entity.id)
                              ? "text-indigo-600"
                              : "text-gray-900",
                          )}
                        >
                          {entity.firstName || ""}
                        </td>
                        <td className="px-3 py-4 text-sm text-gray-500">
                          {entity.lastName || ""}
                        </td>
                        <td className="px-3 py-4 text-sm text-gray-500">
                          <div className="w-40 truncate">
                            {entity.company?.domain && (
                              <Link
                                href={"https://" + entity.company.domain}
                                passHref
                              >
                                <a target="_blank" rel="noopener noreferrer">
                                  <NullValue val={entity.company?.label} />
                                </a>
                              </Link>
                            )}
                            {R.isNil(entity.company?.domain) && (
                              <NullValue val={entity.company?.label} />
                            )}
                            {state.data.user?.hasPlan && (
                              <SocialProfiles
                                profiles={entity.company?.socialProfiles || []}
                              />
                            )}
                          </div>
                        </td>
                        <td className="px-3 py-4 text-sm text-gray-500">
                          <div className="flex">
                            {entity.contactTitle &&
                              entity.currentPosition == true && (
                                <div className="flex-none">
                                  <Tooltip tip="Current position">
                                    <CheckBadgeIcon className="h-5 w-5 text-green-600" />
                                  </Tooltip>
                                </div>
                              )}
                            <div className="w-40 flex-none truncate">
                              <NullValue val={entity.contactTitle} />
                            </div>
                          </div>
                        </td>
                        <td className="px-3 py-4 text-sm text-gray-500">
                          <div className="w-80 flex-none truncate">
                            <UserMaskedValue val={entity.emailAddress} />
                          </div>
                          {state.data.user?.hasPlan &&
                            entity.alternateAddresses &&
                            entity.alternateAddresses.length > 0 && (
                              <div>
                                <a
                                  className="text-xs hover:cursor-pointer"
                                  onClick={() => {
                                    if (idx == alternateIdx) {
                                      setAlternateIdx(undefined);
                                      return;
                                    }
                                    setAlternateIdx(idx);
                                  }}
                                >
                                  {idx == alternateIdx
                                    ? "Hide Alternates"
                                    : "See Alternates"}
                                </a>
                              </div>
                            )}
                        </td>
                      </tr>
                      {alternateIdx === idx &&
                        entity.alternateAddresses &&
                        entity.alternateAddresses.map((alternate, aIdx) => {
                          return (
                            <tr key={"alt" + aIdx}>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0"></td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"></td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"></td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"></td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"></td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {alternate.email}
                              </td>
                            </tr>
                          );
                        })}
                    </Fragment>
                  ))}
                </tbody>
              </table>
            </InfiniteScroll>
          </div>
        </div>
      </div>
      <Modal>
        <ListSelectBox
          onSave={(id) => {
            actOnSelected("ADD_TO_LIST", { listId: id });
            setOpen(false);
          }}
        />
      </Modal>
    </div>
  );
};

const countUndefined = (obj: { [key: string]: any }, keys: string[]) => {
  let out = keys.map((key) => {
    return R.isNil(obj[key]) ? 1 : 0;
  });
  return R.sum(out);
};

const Contacts = () => {
  const limit = 100;
  const [entities, setEntities] = useState<Queries.IEntity[]>([]);
  const [firstLoad, setFirstLoad] = useState(true);
  const {
    state: { isSearching, isFiltering },
  } = FilterAndSearch.useSearch();
  const [canLoadMore, setCanLoadMore] = useState(true);

  const { data, loading, fetchMore } = useQuery(Queries.ENTITIES_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      limit,
      offset: 0,
    },
  });

  useEffect(() => {
    Analytics.track("ProspectsContactListViewed");
  }, []);

  useEffect(() => {
    setCanLoadMore(!isSearching && !isFiltering);
  }, [isSearching, isFiltering]);

  useEffect(() => {
    if (loading) {
      return;
    }
    let newEntities: Queries.IEntity[] = data?.entities || [];
    setEntities(newEntities);

    if (firstLoad) {
      setFirstLoad(false);
    }
  }, [data?.entities]);

  const filteredEntities = useMemo(() => {
    return entities.filter((val) => {
      return !R.isNil(val.firstName) || !R.isNil(val.lastName);
    });
  }, [entities]);

  const reload = async () => {
    setFirstLoad(true);
    await loadMore(true);
    setFirstLoad(false);
  };

  const loadMore = async (reload: boolean = false) => {
    let currentOffset = data.entities.length;
    if (reload) {
      currentOffset = 0;
    }
    await fetchMore({
      variables: {
        offset: currentOffset,
        limit,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (reload) {
          return {
            ...previousResult,
            entities: [...fetchMoreResult.entities],
          };
        }
        if (!fetchMoreResult) {
          setCanLoadMore(false);
          return previousResult;
        }
        return {
          ...previousResult,
          entities: [...previousResult.entities, ...fetchMoreResult.entities],
        };
      },
    });
  };

  if (firstLoad) {
    return (
      <div className="flex justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  if (R.isEmpty(filteredEntities)) {
    return <EmptyList />;
  }

  return (
    <SideFilter>
      <EntitiesTable
        onRefresh={reload}
        entities={filteredEntities}
        fetchMore={loadMore}
        hasMore={canLoadMore}
      />
    </SideFilter>
  );
};

const Entities: NextPage = () => {
  return (
    <FilterAndSearch.Provider indexName={"ner-results"}>
      <Contacts />
    </FilterAndSearch.Provider>
  );
};

export default Entities;
