import React, { useEffect } from "react";
import { useRouter } from "next/router";
import { ChevronRightIcon } from "@heroicons/react/24/solid";
import {
  QuestionMarkCircleIcon,
  ArrowDownTrayIcon,
  CheckIcon,
  MagnifyingGlassIcon,
  NewspaperIcon,
} from "@heroicons/react/24/outline";
import { useQuery } from "@apollo/client";
import { classNames } from "../utils";
import Link from "next/link";
import * as Analytics from "../utils/analytics";
import { useLsDefault } from "./useLsDefault";
import { useSession, Queries } from "../utils/graphql";
import Spinner from "./Spinner";
import NewsScan from "./NewsScan";
import ScanHistory from "../components/ScanHistory";
import Entities from "../components/Entities";

const WelcomeWidget = () => {
  const { state } = useSession();
  if (state.loading) {
    return <Spinner />;
  }
  return (
    <div className="w-1/3 flex-1 flex-col items-start rounded-lg bg-white p-6 shadow-md">
      <h2 className="text-xl font-bold">
        Good day, {state.data.user?.firstname} {state.data.user?.lastname}
      </h2>
      <p className="mt-2">{`Welcome to the MailMentor dashboard.`}</p>
    </div>
  );
};

const StatsWidget = ({
  primary,
  stat,
  loading,
  href,
  buttonCta,
}: {
  primary: string;
  stat: string;
  loading?: boolean;
  href: string;
  buttonCta: string;
}) => {
  const router = useRouter();
  if (loading) {
  }
  return (
    <div className="flex w-1/3 flex-col items-start rounded-lg bg-white p-6 shadow-md">
      <h2 className="text-xl font-bold">{primary}</h2>
      <div className="mt-4 flex w-full items-center justify-between">
        {loading && <Spinner />}
        {!loading && (
          <>
            <div className="flex items-center">
              <h1 className="text-4xl font-bold">{stat}</h1>
            </div>

            <button
              className="mt-4 rounded-md bg-indigo-600 px-4 py-2 text-white"
              onClick={() => {
                router.push(href);
              }}
            >
              {buttonCta}
            </button>
          </>
        )}
      </div>
    </div>
  );
};

const DashboardComponent = () => {
  const { data, loading } = useQuery(Queries.GET_STATS_QUERY);
  return (
    <div>
      <div className="flex space-x-4 p-6">
        <WelcomeWidget />
        <StatsWidget
          primary={"Contacts Collected"}
          stat={data?.stats?.collectedContacts || 0}
          loading={loading}
          href={
            data?.stats?.collectedContacts > 0
              ? "/"
              : "https://chrome.google.com/webstore/detail/mailmentor-prospecting-co/lmaejlipbkiphadkcbhmmpaoephkgmpj"
          }
          buttonCta={
            data?.stats?.collectedContacts > 0 ? "View" : "Install Plugin"
          }
        />
      </div>
      <div className="flex space-x-4 p-6">
        <div className="w-full flex-1 flex-col items-start rounded-lg bg-white p-6 shadow-md">
          <Entities />
        </div>
      </div>
    </div>
  );
};

const Dashboard = () => {
  return <DashboardComponent />;
};

const items = [
  //{
  //name: "Find Leads",
  //description:
  //"Use our Prospecting Agent to retrieve quality business leads.",
  //href: "/prospector",
  //iconColor: "bg-gray-500",
  //icon: UserGroupIcon,
  //hasCompleted: false,
  //},
  //{
  //name: "Launch a Campaign",
  //description:
  //"Create multi-touch email campaigns to connect and engage with prospects.",
  //href: "/campaigns/new",
  //iconColor: "bg-green-500",
  //icon: PaperAirplaneIcon,
  //},
  //{
  //name: "Create Personalized Messages",
  //description:
  //"Use AI to generate personalized messages and templates to use within your outreach.",
  //href: "/personalization-wizard",
  //iconColor: "bg-red-500",
  //icon: UserPlusIcon,
  //hasCompleted: false,
  //},

  //{
  //name: "Optimize Messages",
  //description:
  //"Use MailMentor’s message optimizer to improve your outreach through AI-powered recommendations.",
  //href: "/compose",
  //iconColor: "bg-indigo-500",
  //icon: PencilIcon,
  //hasCompleted: false,
  //},
  {
    name: "Install Browser Extension",
    description:
      "Install the MailMentor prospecting CoPilot and start collecting contacts.",
    href: "https://chrome.google.com/webstore/detail/mailmentor-prospecting-co/lmaejlipbkiphadkcbhmmpaoephkgmpj",
    iconColor: "bg-yellow-500",
    icon: ArrowDownTrayIcon,
    hasCompleted: false,
  },
  {
    name: "News Lead Scan",
    description: "Search the news for leads for your company",
    href: "/news-scan",
    iconColor: "bg-yellow-500",
    icon: NewspaperIcon,
    hasCompleted: false,
  },
  {
    name: "View Collected Contacts",
    description: "See contacts collected by the CoPilot",
    href: "/",
    iconColor: "bg-yellow-500",
    icon: MagnifyingGlassIcon,
    hasCompleted: false,
  },
  // {
  //   name: "Create Sequence Of Messages",
  //   description: "Leverage MailMentor AI to craft a series of messages",
  //   href: "/sequence-builder",
  //   iconColor: "bg-yellow-500",
  //   icon: RectangleStackIcon,
  //   hasCompleted: false,
  // },
  {
    name: "Get Help",
    description:
      "Explore our help center to learn more about MailMentor’s features and answers to your questions.",
    href: "https://help.mailmentor.io?ref=main-app",
    iconColor: "bg-pink-500",
    icon: QuestionMarkCircleIcon,
    hasCompleted: false,
  },
];

const getNoSpaceName = (item: string) => {
  return item.split(" ").join("");
};

const CompleteSteps = () => {
  const [onboardingState, setOnboaringState] = useLsDefault(
    `onboaardingChecklistState:1695907815`,
    {} as { [key: string]: boolean },
  );

  useEffect(() => {
    Analytics.track("GettingStartedLoaded");
  }, []);

  const onClickItem = (itemName: string) => {
    const noSpaceName = getNoSpaceName(itemName);
    Analytics.track("MainViewClick", {
      selection: noSpaceName,
    });

    const newVal = {
      ...onboardingState,
    };
    newVal[noSpaceName] = true;

    setOnboaringState(newVal);
  };

  const processedItems = items.map((item) => {
    const hasCompleted = onboardingState[getNoSpaceName(item.name)];
    if (hasCompleted) {
      item.icon = CheckIcon;
      item.iconColor = "bg-green-500";
    } else {
      item.iconColor = "bg-gray-500";
    }
    return {
      ...item,
      hasCompleted,
    };
  });

  return (
    <div className="mx-auto max-w-lg">
      <h2 className="text-lg font-medium text-gray-900">
        Get started with MailMentor
      </h2>
      <p className="mt-1 text-sm text-gray-500">
        Discover the power of an AI-driven sales process with the below
        onboarding checklist.
      </p>
      <ul
        role="list"
        className="mt-6 divide-y divide-gray-200 border-b border-t border-gray-200"
      >
        {processedItems.map((item, itemIdx) => (
          <li key={itemIdx}>
            <div className="group relative flex items-start space-x-3 py-4">
              <div className="flex-shrink-0">
                <span
                  className={classNames(
                    item.iconColor,
                    "inline-flex h-10 w-10 items-center justify-center rounded-lg",
                  )}
                >
                  <item.icon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </span>
              </div>
              <div className="min-w-0 flex-1">
                <div className="text-sm font-medium text-gray-900">
                  <Link href={item.href}>
                    <a
                      onClick={() => {
                        onClickItem(item.name);
                      }}
                    >
                      <span className="absolute inset-0" aria-hidden="true" />
                      {item.name}
                    </a>
                  </Link>
                </div>
                <p className="text-sm text-gray-500">{item.description}</p>
              </div>
              <div className="flex-shrink-0 self-center">
                <ChevronRightIcon
                  className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                  aria-hidden="true"
                />
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default function MainView() {
  return <Dashboard />;
}
